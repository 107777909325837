.live-alerts-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
  background-color: var(--content-background);
  height: 100%;
}

.live-alerts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.pagination-buttons {
  display: flex;
  gap: 10px;
}

.prev-page-button,
.next-page-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.prev-page-button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.prev-page-button:hover,
.next-page-button:hover {
  background-color: var(--hover-background);
}

.live-alerts-header h1 {
  margin: 0;
  font-size: 24px;
  color: white;
}

.earf-blue {
  color: var(--earf-blue);
}

.live-alerts-tabs {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.live-alerts-tabs button {
  padding: 8px 15px;
  background: none;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.live-alerts-tabs button:hover {
  color: var(--earf-blue);
}

.live-alerts-tabs button.active-tab {
  color: var(--earf-blue);
  font-weight: bold;
}

.live-alerts-content {
  margin-top: 15px;
}

@media (max-width: 768px) {


  .live-alerts-header h1 {
    font-size: 16px;
  }

  .pagination-buttons {
    gap: 5px;
  }

  .header-container h2 {
    font-size: 16px;
  }

  .prev-page-button,
  .next-page-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .live-alerts-tabs button {
    padding: 6px 10px;
    font-size: 12px;
  }
}