/* src/ManualDescription/ManualDescription.css */
.manual-description-container {
  width: 95%;
  border-radius: 10px;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  position: relative;
  background-color: #18181b;
  padding: 20px;
}

.manual-name {
  font-size: 20px;
  font-weight: 600;
  color:var(--earf-blue);
}

.manual-description {
  color: #d3d3d3;
  font-size: 1rem;
  margin: 0;
  text-align: left;
  margin-bottom: 10px;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .manual-description  {
    font-size: 0.9rem;
    line-height: 1.4;
  }
}

