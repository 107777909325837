.feature-creator-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;
  color: white;
  overflow-y: auto; /* Changed from scroll to auto */
  height: 100%;
  background-color: var(--content-background);
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.button-green {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.darkFormGroup {
  border-radius: 4px;
}

.darkFormGroup label {
  color: #fff;
  display: block;
  margin-bottom: 0.5rem;
  border: none;
}

.darkTextarea {
  width: 85%;
  height: 6rem;
  background-color: #1d1d1d;
  color: #fff;
  border-radius: 4px;
  border: none;

}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #f1f1f1;
  font-family: Arial, sans-serif;
  text-align: center;
}

.popup p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #cfcfcf;
}

.popup-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #007acc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-button:hover {
  background-color: #005fa3;
}

.popup-cancel {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  background-color: #3a3a3a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-cancel:hover {
  background-color: #555555;
}

.popup button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #555555;
}

.button-red {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.generate-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-button-dark {
  background-color: #444;
  color: #fff;
  padding: 10px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button-dark:hover {
  background-color: #666;
}

.toggle-button-dark:active {
  background-color: #555;
}

.json-input-container {
  margin-top: 10px;
}

.json-textarea-dark {
  width: 100%;
  height: 100px;
  background-color: #333;
  color: #eee;
  border: 1px solid #555;
  padding: 10px;
  border-radius: 5px;
  resize: vertical;
}

.json-textarea-dark::placeholder {
  color: #aaa;
}

.parse-button-dark {
  background-color: #555;
  color: #fff;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.parse-button-dark:hover {
  background-color: #777;
}

.parse-button-dark:active {
  background-color: #666;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-section {
  flex-basis: 48%;
  margin-bottom: 20px;
}

.form-section-full {
  flex-basis: 100%;
  margin-bottom: 20px;

}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 16px;

}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #1d1d1d;
  color: white;
  font-weight: bold;
  max-width: 900px;
  border: none;
}

.submit-button {
  padding: 12px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  width: 85%;
  text-align: center;
  margin-bottom: 50px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
/* In your .module.css file: */
.formGroupMargin {
  margin-top: 10px;
}

.modal-content {
  background: #2b2b2b;
  color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
}

.wikidata-results {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background: #3b3b3b;
}

.result-actions {
  display: flex;
  gap: 10px;
}

.use-button {
  padding: 5px 10px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.use-button:hover {
  background: #45a049;
}

.view-button {
  padding: 5px 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-button:hover {
  background: #0056b3;
}

.close-modal-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.close-modal-button:hover {
  background: #c0392b;
}

.search-wikidata-button {
  margin-left: 10px;
  padding: 10px 15px;
  background: var(--earf-blue);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background 0.3s, transform 0.2s;
}

.modal-content,
.result-item {
  transition: background 0.3s, color 0.3s;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 768px) {
  .form-section {
    flex-basis: 100%;
  }

  .generate-button {
    font-size: 14px;
  }
}