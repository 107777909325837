.facts-container {
  color: white;
  border-radius: 5px;
  padding: 10px;
  width: 300px;
  max-width: 300px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  text-align: left;
  min-height: 50px;
  position: relative;
  margin-top: 300px;
  background-color: #1a1a1a00;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.facts-container[style*="visible"] {
  opacity: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  color: var(--earf-blue);
  margin: 0;
  flex: 1;
}

.year {
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}

.icon {
  font-size: 1.2rem;
  color: var(--earf-blue);
}

.text {
  font-size: 0.9rem;
  line-height: 1.6;
  color: white;
  margin-top: 15px;
}

.facts-container a {
  color: var(--earf-blue);
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.3s ease;
}

.facts-container a:hover {
  color: #0077cc;
}

.error {
  color: red;
  font-weight: bold;
  text-align: center;
}

.loading {
  color: white;
  font-style: italic;
  margin-top: 10px;
  text-align: center;
}

.fact {
  margin-bottom: 1.5rem;
  z-index: 2;
}

@media (max-width: 768px) {
  .facts-container {
    max-width: 90%;
    padding: 15px;
    margin-top: 400px;
  }

  .header {
    padding: 8px 0;
  }

  .title {
    font-size: 0.9rem;
  }

  .year {
    font-size: 0.7rem;
    gap: 3px;
  }

  .icon {
    font-size: 1rem;
  }

  .text {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .loading {
    font-size: 0.8rem;
  }
}