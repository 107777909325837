.feature-row-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px
}

.feature-row-item {
  display: flex;
  align-items: center;
  background-color: var(--components);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  height: auto;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.feature-row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  
}

.feature-row-image-wrapper {
  position: relative;
  width: 80px;
  height: 100%;
}

.feature-row-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.loading {
  filter: blur(20px);
  opacity: 0.5;
}

.loaded {
  filter: none;
  opacity: 1;
}

.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.2);
  filter: blur(20px);
}

.feature-row-text {
  margin-left: 10px;
  flex: 1;
}

.feature-row-title {
  color: var(--earf-blue);
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.feature-row-description {
  font-size: 14px;
  color: rgb(184, 184, 184);
  margin: 0;
}

.feature-row-item:hover {
  transform: scale(1.02);
}

@media (max-width: 1024px) {
  .feature-row-container {
    gap: 15px;
    flex-wrap: wrap;
  }

  .feature-row-item {
    max-width: 300px;
  }

  .feature-row-image-wrapper {
    width: 70px;
  }

  .feature-row-text {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .feature-row-container {
    justify-content: space-around;
  }

  .feature-row-item {
    max-width: 100%;
    height: 90px;
  }

  .feature-row-image-wrapper {
    width: 60px;
  }

  .feature-row-text {
    margin-left: 8px;
  }
}

@media (max-width: 480px) {
  .feature-row-item {
    height: auto;
    padding: 10px;
  }

  .feature-row-image-wrapper {
    width: 50px;
  }

  .feature-row-text {
    margin-left: 5px;
  }

  .feature-row-title {
    font-size: 14px;
  }

  .feature-row-description {
    font-size: 12px;
  }
}