.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

.body-layout {
  display: flex;
  flex: 1;
  height: calc(100vh - 64px);
  margin: 0;
}

.main-content {
  flex-grow: 1;
  height: 100%;
  transition: all 0.3s ease;
  margin: 0 auto;
  padding: 0;
  position: relative;
}

.earf-blue {
  color: var(--earf-blue);
  font-weight: bold;
}

.full-container {
  width: 100%;
  height: 100vh;
  background-color: var(--content-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.spinner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers image & text */
  justify-content: center;
  text-align: center;
  gap: 10px;
  /* Space between image & text */
}

.browse-loading-text {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color);
  text-align: center;
  margin: 0;
  animation: fadeIn 1s ease-in-out;
  min-height: 20px;
  margin-bottom: 50px;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.earf-subtext {
  font-size: 1rem;
  color: white;
  text-align: center;
  margin: 0;
  /* Ensures no extra margin */
}

.spinner {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 3px;
  background:
    radial-gradient(farthest-side, rgba(104, 223, 223, 0.733) 95%, #0000) 50% 0/12px 12px no-repeat,
    radial-gradient(farthest-side, #0000 calc(100% - 5px), rgba(104, 223, 223, 0.3)calc(100% - 4px)) content-box;
  animation: l6 2s infinite;
}

@keyframes l6 {
  to {
    transform: rotate(1turn)
  }
}

.footer-text {
  color: grey;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.search-input {
  background-color: #1b1b1b;
  border: 1px solid #5555554f;
  border-radius: 5px;
  padding: 8px 12px;
  color: white;
  font-size: 13px;
  min-width: 200px;
  transition: width 0.3s ease-in-out;
  margin-right: 10px;
  width: 200px;
}

.search-input:focus {
  outline: none;
  border-color: var(--secondary-text-color);
  width: calc(200px + 50px);
}

@media (max-width: 768px) {
  .search-input {
    width: 150px;
    min-width: 150px;
  }

  .search-input:focus {
    width: calc(150px + 50px);
  }
}

@media (max-width: 480px) {
  .search-input {
    width: 120px;
    min-width: 120px;
  }

  .search-input:focus {
    width: calc(120px + 50px);
  }
}

.back-button {
  background-color: #18181b;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: 600;
  width: 100px;
  letter-spacing: 1px;
}

.back-button:hover {
  background-color: var(--hover-background);
  transform: scale(1.05);
}

.mobile-back-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bold;
  width: 95%;
}

.mobile-back-button:hover {
  background-color: var(--hover-background);
  transform: scale(1.02);
}

.main-content.expanded {
  margin-left: 250px;
}

.main-content.collapsed {
  margin-left: 45px;

}

.youtube-spinner {
  width: 150px;
  height: auto;
  animation: pulse 2.5s infinite ease-in-out;
  margin-bottom: 50px;

}

.image-spinner {
  width: 100px;
  height: auto;
  animation: pulse 3s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    background-color: var(--content-background);
    flex-grow: 1;
    height: 100%;
    transition: all 0.3s ease;
    margin-left: 0;
  }


  .main-content.expanded {
    margin-left: 0;
  }

  .main-content.collapsed {
    margin-left: 0;
  }
}


.footer-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 14px;
  color: #bbb;
  font-weight: 500;
}

.footer-content {
  display: flex;
  align-items: center;
  gap: 3px;
}

.from-text {
  margin-bottom: -30px;
  font-size: 14px;
  color: #7396a7bb;
  font-weight: bold;
  letter-spacing: 1px;
}

.earf-logo {
  height: 30px;
}

.earf-text {
  font-size: 25px;
  font-weight: bold;
  color: var(--earf-blue, #68dfdfbb);
}


.loader-footer {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  padding: 20px;
  text-align: center;
  color: #fff;
}

.footer-content p {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.5;
}

.tick-icon {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 2px;
}

.earf-highlight,
.earf-highlight:link,
.earf-highlight:visited,
.earf-highlight:active {
  color: #68dfdfbb !important;
  text-decoration: none;
  font-weight: 700;
}

.earf-highlight:hover {
  color: #0786c5 !important;
}


.loading-footer-link,
.loading-footer-link:link,
.loading-footer-link:visited,
.loading-footer-link:active {
  color: #68dfdfbb !important;
  text-decoration: none;
  font-weight: 700;
}

.loading-footer-link:hover {
  color: #0786c5 !important;
}



.reload-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: var(--content-background);
  display: flex;
  flex-direction: column;
  
}


.reload-loader-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.reload-loader-image {
  max-width: 100%;
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
  animation: pulse 3s ease-in-out infinite;
}

.reload-loader-footer {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  width: 100vw;
  text-align: center;
  padding: 1rem;
  justify-content: center;


}
.reload-footer-content{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;

}
.reload-footer-content p {
  font-size: 16px  !important;

}
.reload-loader-earf-text {
  font-weight: bold;
  color: var(--earf-blue);
  margin: 0;
  font-size: 20px;
}