.verify-content {
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #ddd;
    /* Greyish white for body text */
    background-color: var(--content-background);
    overflow-y: auto;
    /* Allow scrolling if content exceeds viewport */

}

.verify-container {
    max-width: 1500px;

    margin-bottom: 100px;

}

.verify-container h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #68dfdfbb;
    display: flex;
    align-items: left;
    justify-content: left;
}

.verify-logo {
    width: 35px;
    height: auto;
    margin-right: 15px;
}

.verify-container p {
    margin-bottom: 0.8rem;
    color: #ddd;
    /* Greyish white text */
}

.verify-container ol {
    margin-left: 10px;
    margin-bottom: 0.8rem;
    margin-bottom: 1.5rem;
    color: #ddd;
    /* Greyish white for lists */
}

.verify-container ul {
    margin-left: 10px;
    margin-bottom: 1rem;
    color: #ddd;
}

.verify-container li {
    margin-bottom: 0.8rem;
}

.verify-email,
.verify-email:link,
.verify-email:visited,
.verify-email:active {
    color: #68dfdfbb !important;
    text-decoration: none;
    font-weight: 700;
}

.verify-email:hover {
    color: #0786c5 !important;
}



/* For highlighting "EARF" within text */
.earf-highlight {
    font-weight: 700;
    color: #68dfdfbb;
    display: inline-block;
}

.verify-notice {
    display: inline-block; /* Shrink to fit content */
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px 12px;
    border-left: 4px solid #68dfdfbb;
    margin-bottom: 20px;
    border-radius: 4px;
    color: #ddd;
    font-weight: 800;
  }
  
  .verify-notice p {
    margin: 0;
    color: #fff;
  }
  .verify-notice1 {
    display: inline-block; /* Shrink to fit content */
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px 12px;
    border-left: 4px solid #68dfdfbb;
    margin-bottom: 20px;
    border-radius: 4px;
    color: #ddd;
    font-weight: 800;
  }

  .verify-notice1 p {
    margin: 0;
    color: #fff;
    font-size: 12px !important;

  }
  
  .verification-note {
    display: inline-block; /* Shrink to fit content */
    background-color: rgba(0, 0, 0, 0.3);
    padding: 8px 12px;
    border-left: 4px solid #68dfdfbb;
    margin-bottom: 20px;
    border-radius: 4px;
    color: #ddd;
    font-weight: 800;
    max-width: auto;
  }
  .verify-benefits {
    color: var(--text-color); /* Matches existing site text color */
    line-height: 1.4; /* Improves readability */
  }
  
  .verify-benefits ul {
    padding-left: 20px; /* Ensures proper indentation */
  }
  
  .verify-benefits li {
    font-size: 14px; /* Adjust size as needed */
    margin-bottom: 5px; /* Adds slight spacing for clarity */
  } 

/* MEDIA QUERIES */

/* ----- MOBILE: up to 480px ----- */
@media (max-width: 480px) {
    .verify-content {
      padding: 10px;
    }
    
    .verify-container h1 {
      font-size: 1.2rem;
    }
    
    .verify-container h3 {
      font-size: 1rem;
    }
    
    .verify-logo {
      width: 25px;
    }
      
    .verify-container {
    max-width: 95%;

    }
    .verify-container p,
    .verify-container ol,
    .verify-container ul {
      font-size: 0.9rem;
      line-height: 1.5;
    }
    
    .verify-container li {
      margin-bottom: 0.8rem;
    }
    
    .verify-notice,
    .verification-note {
      font-size: 0.85rem;
      padding: 5px 8px;
      margin-bottom: 10px;
    }
  }
  
  /* ----- SMALL TABLETS: 481px to 767px ----- */
  @media (min-width: 481px) and (max-width: 767px) {
    .verify-content {
      padding: 15px;
    }
    
    .verify-container {
        max-width: 95%;

    }
    
    .verify-container h1 {
      font-size: 1.3rem;
      margin-bottom: 15px;
    }
    
    .verify-container h3 {
      font-size: 1.1rem;
      margin-bottom: 10px;
    }
    
    .verify-logo {
      width: 30px;
      margin-right: 10px;
    }
    
    .verify-container p,
    .verify-container ol,
    .verify-container ul {
      font-size: 0.95rem;
      line-height: 1.5;
    }
    
    .verify-container li {
      margin-bottom: 1rem;
    }
    
    .verify-notice,
    .verification-note {
      font-size: 0.9rem;
      padding: 6px 10px;
      margin-bottom: 15px;
    }
  }
  
  /* ----- TABLETS & SMALL LAPTOPS: 768px to 1024px ----- */
  @media (min-width: 768px) and (max-width: 1024px) {
    .verify-content {
      padding: 20px;
    }
    
    .verify-container {
      max-width: 95%;
      margin: 0 auto;
    }
    
    .verify-container h1 {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
    
    .verify-container h3 {
      font-size: 1.2rem;
      margin-bottom: 15px;
    }
    
    .verify-logo {
      width: 35px;
    }
    
    .verify-container p,
    .verify-container ol,
    .verify-container ul {
      font-size: 1rem;
      line-height: 1.5;
    }
    
    .verify-container li {
      margin-bottom: 1rem;
    }
    
    .verify-notice,
    .verification-note {
      font-size: 1rem;
      padding: 8px 12px;
      margin-bottom: 20px;
    }
  }
  
  /* ----- DESKTOP: above 1024px ----- */
  @media (min-width: 1025px) {
    .verify-content {
      padding: 20px;
    }
    
    .verify-container {
      max-width: 1200px;
      max-width: 95%;

    }
    
    .verify-container h1 {
      font-size: 1.5rem;
    }
    
    .verify-container h3 {
      font-size: 1.2rem;
    }
    
    .verify-logo {
      width: 40px;
    }
    
    .verify-container p,
    .verify-container ol,
    .verify-container ul {
      font-size: 1rem;
      line-height: 1.4;
    }
    
    .verify-container li {
      margin-bottom: 1rem;
    }
    
    .verify-notice,
    .verification-note {
      font-size: 1rem;
      padding: 8px 12px;
    }
  }