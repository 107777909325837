.disclaimer-container {
  padding: 20px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: var(--content-background);
  height: 100%;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disclaimer-header {
  font-size: 25px;
  font-weight: bold;
  color: var(--secondary-text-color);
}

.disclaimer-content {
  line-height: 1.6;
  font-size: 16px;

  color: white;
  overflow-y: auto;
  height: calc(100vh - 150px);
}

.disclaimer-content h3 {
  font-size: 18px;
  margin-top: 20px;
  color: var(--secondary-text-color);
}

.disclaimer-content ul {
  margin-left: 20px;
}

.disclaimer-content ul li {
  margin-bottom: 10px;
}

.disclaimer-content p strong {
  font-weight: bold;
  color: var(--secondary-text-color);
}

.disclaimer-content a {
  font-weight: bold;
  color: var(--secondary-text-color);
  text-decoration: none;
}