.infocontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  border-bottom: 1px solid #5a5a5aa8;
  color: rgb(206, 206, 206);
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin: 0;
  padding: 0;
  color: white !important;
  font-size: 1rem;
}
.skeletonFlagRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skeletonFlag {
  width: 50px;
  height: 30px;
  border-radius: 4px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  animation: skeleton-loading 1.5s infinite;
}

.skeletonTitle {
  width: 150px;
  height: 25px;
  border-radius: 6px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  animation: skeleton-loading 1.5s infinite;
}

.skeletonMotto {
  width: 200px;
  height: 18px;
  margin-top: 8px;
  border-radius: 4px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  animation: skeleton-loading 1.5s infinite;
}

.skeletonButtons {
  display: flex;
  gap: 15px;
  margin-right: 20px;
}

.skeletonIcon {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  animation: skeleton-loading 1.5s infinite;
}

.skeletonDescription {
  margin-top: 10px;
  width: 100%;
  height: 60px;
  border-radius: 6px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  animation: skeleton-loading 1.5s infinite;
  margin-bottom: 20px
}
@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.headerRow {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.countryName {
  color: white;
}

.flag {
  width: 50px;
  height: 30px;
  border-radius: 4px;
}

.motto {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: var(--earf-blue);
  font-style: italic;
}

.buttons {
  display: flex;
  gap: 15px;
  margin-right: 20px;
  justify-content: flex-end;
  align-items: center;
}

.liveText {
  color: var(--earf-blue);
}

.centeredcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.descriptionloader {
  width: 35px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #68dfdfbb 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.alert {
  display: flex;
  align-items: center;
  color: #ff4d4f;
  font-size: 14px;
  background-color: var(--content-background);
  font-weight: 600;
}

.alertIcon {
  font-size: 25px;
  margin-right: 10px;
  animation: flash 2s infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 10;
  pointer-events: none;
}

.iconButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.iconButton img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.iconButton:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.description {
  font-size: 0.9rem;
  color: #e0e0e0;
  padding-bottom: 10px;
}

.tags {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  color: #333;
  font-weight: bold;
}

button.iconButton {
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.infoIcon {
  color:white;
  margin-left: 5px;
  font-size: 20px
}
.infoIcon:hover  {
  color:var(--earf-blue);
  margin-left: 5px
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .countryName {
    font-size: 1rem; /* Adjust for very small screens */
  }

  .motto {
    font-size: 16px;
  }

  .description {
    display: none; /* Hide description on very small screens */
  }

  .alert {
    display: flex;
    flex-direction: column;  /* Align alert content vertically */
    align-items: flex-start;
    color: #ff4d4f;
    font-size: 11px;
    background-color: var(--content-background);
    font-weight: 600;
    margin-top: 10px; /* Add some space above the alert */
  }

  .iconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
  }

  .topRow {
    flex-direction: column;  /* Stack country name, flag, and motto vertically */
    align-items: flex-start;
    gap: 10px; /* Add some space between the elements */
  }

  .buttons {
    flex-direction:row;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 10px
  }
}
