.country-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;

}

.country-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.country-row-title {
    font-size: 20px;
    color: white;
    font-weight: bold;
}

.country-view-all-btn {
    padding: 10px 20px;
    background-color: #33333300;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}

.country-view-all-btn:hover {
    color: var(--earf-blue);
}

.country-row-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 10px;
    padding-bottom: 10px;
    width: 100%;
    overflow-x: hidden;
    text-align: left;
}

.country-item-wrapper {
    flex: 1 0 150px;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: white;
    text-align: left;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;

}

.country-item-wrapper:hover {
    transform: scale(1.02);
}

.country-flag {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
    transition: filter 0.3s ease, opacity 0.3s ease;

}

.country-name {
    margin-top: 2px;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
}




.loading {
    filter: blur(20px);
    opacity: 0.5;
}

.loaded {
    filter: none;
    opacity: 1;
}

.image-placeholder {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.2);
    filter: blur(20px);
}