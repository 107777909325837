.status-container {
  position: absolute;
  top: 10px;
  left: 20px;
  padding: 10px;
  background-color: #141517;
  border-radius: 8px;
  color: white;
}

.status-row {
  display: flex;
  align-items: center;
}

.green-circle,
.red-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.green-circle {
  background-color: #4CAF50;
}

.red-circle {
  background-color: #ff4d4d;
}

.status-text {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.server-url {
  font-size: 10px;
  color: #cfd1d4;
  margin-left: 5px;
}

.server-details {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.6;
}

.server-details p {
  margin: 0;
  color: #cfd1d4;
}

.server-details p:nth-child(2) {
  margin-top: 5px;
}

.server-url {
  font-size: 10px;
  color: #cfd1d4;
  margin-left: 5px;
  cursor: pointer;
}

.url-link {
  text-decoration: none;
  color: inherit;
}

.url-link:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .status-container {
    position: static;
    width: 50%;
    max-width: 300px;
  }

  .status-text {
    font-size: 14px;
  }

  .server-details {
    font-size: 12px;
  }

  .green-circle,
  .red-circle {
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 480px) {
  .status-container {
    width: 100%;
    padding: 10px;
  }

  .status-text {
    font-size: 12px;
  }

  .server-details {
    font-size: 11px;
  }

  .green-circle,
  .red-circle {
    width: 8px;
    height: 8px;
  }
}