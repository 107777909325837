/* Dark theme styling for the country details page */

.container {
    margin: 0 auto;
    padding: 20px;
    background-color: var(--content-background);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: var(--text-light);
    overflow-y: auto; /* Make the entire container scrollable */
    height: 100vh; /* Ensure full height */
    /* Adjust height to account for potential header */
    display: flex;
    flex-direction: row;
    align-items: left;
    text-align: left;
    width: 100%;
    color: rgb(223, 223, 223);
}

.flagContainer {
    text-align: left;
    margin-right: 10px;
    /* Reduced gap to 10px */
    flex: 1;
}

.flag {
    width: 100%;
    width: 300px;
    height: auto;
    border-radius: 8px;
    padding-right: 20px
}

.coatOfArms {
    margin-top: 20px;
    width: 100%;
    max-width: 250px;
    height: auto;
    display: block;
    text-align: left;
    margin-right: 50px;
    /* Reduced gap to 10px */
    flex: 1;
}

.detailsContainer {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    max-width: 1200px;
}

.details {
    flex: 2;
    overflow-y: auto;
}

.section {
    margin-bottom: 20px;
}

p {
    font-size: 1rem;
    color: var(--text-muted);
    margin: 5px 0;
}

strong {
    font-weight: bold;
}

ul {
    padding-left: 20px;
    color: var(--text-muted);
}

ul li {
    list-style-type: disc;
}

ul li a {
    color: var(--link-color);
    text-decoration: none;
}

ul li a:hover {
    text-decoration: underline;
}

/* Loader and error styles */
.loader {
    text-align: center;
    font-size: 1.5rem;
    color: var(--primary-color);
}

.error {
    text-align: center;
    font-size: 1.2rem;
    color: #FF0000;
}

.description {
    max-width: 80%;
}

.backButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: var(--primary-color);
    color: var(--text-light);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    font-size: 16px;
    font-weight: 800px;
    color: black
}

.backButton:hover {
    background-color: var(--primary-color-dark);
    transform: scale(1.05);
}

.backButton:active {
    background-color: var(--primary-color-darker);
    transform: scale(0.95);
}

.earfBlue {
    color: var(--earf-blue);
    font-size: 25px !important;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding: 10px;
    height: 95vh; /* Ensure full height on mobile */
    overflow-y: auto; /* Make the entire container scrollable */
  }

  .flagContainer,
  .coatOfArms {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }

  .flag,
  .coatOfArms {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }

  .detailsContainer {
    flex-direction: column;
    width: 100%;
    height: 95vh; /* Adjust height for mobile */
  }

  .details {
    width: 100%;
    height: auto; /* Adjust height for mobile */
  }

  .section {
    margin-bottom: 15px;
  }

  p {
    font-size: 0.9rem;
  }

  .backButton {
    top: 5px;
    right: 5px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .earfBlue {
    font-size: 20px !important;
  }
  .coatOfArms {
 display: none;
  }
}