.searchBar {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  padding: 10px 14px;
  border-radius: 12px;
  border: none;
  background: #1e1e1e;
  color: #eee;
  width: 350px;
  font-size: 14px;
  outline: none;
  transition: background 0.2s, box-shadow 0.2s;
}

.input:focus {
  background: #292929;
  box-shadow: 0 0 8px rgba(0, 255, 200, 0.5);
}

.results {
  position: absolute;
  top: 110%;
  left: 0;
  background: #141414;
  max-width: 380px;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  border-radius: 12px;
  margin-top: 5px;
  z-index: 999;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.85);
  border: 1px solid rgba(0, 255, 200, 0.15);
  backdrop-filter: blur(8px);
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 8px;
}

.results::-webkit-scrollbar {
  display: none;
}

.resultItem {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  transition: background 0.2s;
  border-radius: 8px;
}

.resultItem:hover {
  background: var(--hover-background);
}

.thumbnail {
  width: 60px;
  height: 36px;
  object-fit: cover;
  border-radius: 6px;
  flex-shrink: 0;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info strong {
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
}

.info .wikidata {
  color: #dadada;
  font-size: 11px;
  margin-top: 2px;
}

.info p {
  color: #dadada;
  font-size: 12px;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.flag {
  margin-left: 3px;
  width: 15px;
  height: 15px;
  margin-right: 4px;
  border-radius:50%;
  vertical-align: middle;
}

.noResults {
  padding: 14px;
  color: #888;
  text-align: center;
  font-size: 13px;
}
.skeletonThumbnail {
  width: 60px;
  height: 36px;
  background: #333;
  border-radius: 6px;
  flex-shrink: 0;
  animation: pulse 1.2s infinite;
}

.skeletonLine {
  width: 90%;
  height: 12px;
  background: #333;
  margin-bottom: 6px;
  border-radius: 4px;
  animation: pulse 1.2s infinite;
}

.skeletonSmall {
  width: 60%;
  height: 10px;
  background: #333;
  border-radius: 4px;
  animation: pulse 1.2s infinite;
}

.skeletonChannel {
  width: 70%;
  height: 10px;
  background: #333;
  margin-top: 4px;
  border-radius: 4px;
  animation: pulse 1.2s infinite;
}

.partnerIcon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  vertical-align: middle;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.searchWrapper {
  position: relative;
  width: 100%;
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--earf-blue);
  font-size: 16px;
  pointer-events: none;
}

.input {
  padding-left: 35px; /* Make space for icon */
  /* your existing input styles */
}
