.home-page-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 30px;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  margin-bottom: 50px;
  scrollbar-width: none;
  background-color: var(--content-background);
}

.home-page-container::-webkit-scrollbar {
  display: none;
}

.spacer-line1 {
  display: block;
  height: 1px;
  width: 100%;
  background-color: rgb(66, 65, 65);
  opacity: 0.8;
  margin: 20px 0;
  z-index: 9999;
}

.spinner-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.earf-text1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #00aabb;
  text-align: center;
  margin-top: -10px;
  margin-bottom: -250px;
}

.welcome-text {
  margin-top: 0px;
  font-size: 1.2rem;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  .earf-text1 {
    margin-bottom: -200px;
  }
}