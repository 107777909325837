.audio-player-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  max-height: 200px;
  background: rgba(20, 20, 20, 0.925);
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  z-index: 10000;
}

.audio-player-container.show {
  transform: translateY(0);
}
.audio-player-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.top-section {
  display: flex;
  align-items: center;
  width: 100%;
}

.favicon-icon {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.station-info-header {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  max-width: 180px;
  margin-left: 10px;
}

.station-info-header span {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-text 5s linear infinite;
}

.station-info-header span.scroll {
  animation: scroll-text 5s linear infinite;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.bottom-section {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.play-pause-button,
.stop-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: color 0.3s ease;
}

.play-pause-button:hover,
.stop-button:hover {
  color: rgba(104, 223, 223, 0.733);
}

.volume-slider {
  width: 100px;
  margin-left: 10px;
}

/* Mobile version: full bottom 100vw width and 200px height */
@media (max-width: 768px) {
  .audio-player-container {
    width: 50vw;
    height: 100px;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
  .favicon-icon {
    width: 25px;
    height: 25px;
  }
  
  .station-info-header {
    font-size: 12px;
    max-width: 170px;
  }
  
  .play-pause-button,
  .stop-button {
    font-size: 18px;
  }
  
  .volume-slider {
    width: 80px;
  }
}