.category-section {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-decoration: none;
    margin-bottom: 100px;


}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category-title {
    font-size: 20px;
    color: white;
}

.view-all-button {
    padding: 10px 20px;
    background-color: #33333300;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;

}

.view-all-button:hover {
    color: var(--earf-blue);
}

.category-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    overflow: hidden;
    overflow-x: hidden;
    text-decoration: none;
}

.category-item {
    flex: 1 0 150px;
    max-width: 120px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    padding: 0px;
    color: white;
    text-align: left;
    cursor: pointer;
    text-decoration: none;

}

.category-item:hover {
    transform: scale(1.02);
}

.category-image-wrapper {
    position: relative;
    width: 100%;
    height: 150px;
}

.category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: filter 0.3s ease, opacity 0.3s ease;
}

.loading {
    filter: blur(10px);
    opacity: 0.5;
}

.loaded {
    filter: none;
    opacity: 1;
}

.image-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.2);
    filter: blur(10px);
}

.category-name {
    margin-top: 2px;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
}

@media (max-width: 768px) {
    .category-item {
        max-width: 100px;
    }

    .category-image-wrapper {
        height: 120px;
    }
}

@media (max-width: 480px) {
    .category-item {
        max-width: 80px;
    }

    .category-image-wrapper {
        height: 100px;
    }
}