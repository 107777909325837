.description {
  width: 95%;
  border-radius: 10px;
  line-height: 1.6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  margin-bottom: 50px;
  position: relative;
  background-color: #18181b;
  padding: 20px;
}


.descriptionnotice {
  color: rgba(196, 196, 196, 0.658) !important;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  font-style: italic;
  text-align: right !important;
  position: absolute;
  bottom: 5px;
  right: 0px;
}

.description h2 {
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.description p {
  color: #d3d3d3;
  font-size: 1rem;
  margin: 0;
  text-align: left;
  margin-bottom: 10px;
  margin-right: 10px;

}

.highlight-blue {
  color: var(--earf-blue);
}

.about-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 0;
  padding: 0;
}

.about-header {
  margin: 0;
  padding: 0;
  line-height: 1;
}


.flag {
  margin-left: 5px;
  vertical-align: middle;
  width: 35px;
  height: 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linksContainer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.link {
  position: relative;
  display: inline-block;
}


.link::after {
  content: attr(title);
  position: absolute;
  top: 50%;
  right: 110%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}


.link:hover::after {
  opacity: 1;
  visibility: visible;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.link:hover .icon {
  transform: scale(1.1);
}

.skeletonloader {
  display: flex;
  margin: 10px auto;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  padding: 20px;
  background-color: #18181b;
  border-radius: 10px;
}

.skeletonHeader,
.skeletonText {
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

.skeletonHeader {
  width: 60%;
  height: 20px;
}

.skeletonText {
  width: 100%;
  height: 15px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 768px) {
  .description {
    padding: 15px;
    margin-bottom: 30px;
  }

  .description h2 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .description-image {
    width: 150px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
    margin-top: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .description p {
    margin-top: 25px;
    font-size: 0.9rem;
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .description {
    padding: 10px;
    margin-bottom: 20px;
  }

  .description-image {
    width: 50px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
    margin-top: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }


}