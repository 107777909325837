.serverLogContainer {
    max-width: 500px;
    margin: 20px auto;
    padding: 10px;
    background-color: #141517;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 150px;
    left: 20px;
}

.serverLogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.title {
    margin-right: 5px;
    color: white;
    font-weight: 600;
    font-size: 18px;
}

.serverUrl {
    color: #cfd1d4;
    font-size: 12px;
    font-weight: 500;
}

.tabs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}

.tabButton {
    background-color: #1e2023;
    border: none;
    color: #cfd1d4;
    font-size: 12px;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
    text-align: center;
}

.tabButton:hover {
    background-color: #282a2d;
}

.activeTab {
    background-color: var(--earf-blue);
    color: black;
}

.logContainer {
    max-height: 600px;
    overflow-y: auto;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #1e2023;
    padding: 5px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.row td {
    padding: 8px 10px;
    border-bottom: 1px solid #333;
    color: white;
    font-size: 14px;
    word-wrap: break-word;
}

.timestamp {
    font-size: 12px;
    color: #f0f0f0;
    margin-bottom: 2px;
    display: block;
}

.message {
    font-size: 14px;
    color: white;
}

.row[data-log-type="LOGIN ATTEMPT"] {
    background-color: #b3742c67;
}

.row[data-log-type="UPDATER"] {
    background-color: #7347a53d;
    color: #cce7ff;
}

.row[data-log-type="CREATER"] {
    background-color: #3d8048;
    color: #a8ffab;
}

.row[data-log-type="DELETER"] {
    background-color: #522727;
    color: #ffabab;
}

.row[data-log-type="SITEMAP"] {
    background-color: #2d2d40;
    color: #ccccff;
}

.loading,
.error {
    text-align: center;
    color: #666;
    font-size: 16px;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .serverLogContainer {
        max-width: 95%;
        padding: 15px;
        margin: 10px auto;
        position: relative;
    }

    .logContainer {
        max-height: 400px;
    }

    .title {
        font-size: 16px;
    }

    .tabButton {
        font-size: 10px;
        padding: 6px 8px;
    }

    .row td {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .serverLogContainer {
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }

    .tabs {
        flex-direction: column;
        gap: 8px;
    }

    .tabButton {
        font-size: 9px;
        padding: 5px;
    }

    .logContainer {
        max-height: 300px;
    }

    .title {
        font-size: 14px;
    }

    .row td {
        font-size: 11px;
        padding: 6px 8px;
    }
}