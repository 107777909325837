.dashboard-container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    position: relative;
    background-color: var(--content-background);

}

.logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #c82333;
}

.dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.title-container {
    display: flex;
    align-items: center;
}

.dashboard-title {
    font-size: 36px;
    margin-left: 15px;
    color: white;
}

.admin-highlight {
    color: rgba(104, 223, 223, 0.733);
}

.dashboard-logo {
    width: 50px;
    height: auto;
}

.dashboard-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    width: 100%;
    max-width: 100%;
}

.dashboard-item {
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: #141517;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
}

.item-icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-item:hover {
    background-color: var(--hover-background);
    transform: translateY(-5px);
    color: black;
}

.dashboard-item h3 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #ffffff;
}

.dashboard-item p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #c7c4c4;
}

@media (max-width: 768px) {
    .dashboard-title {
        font-size: 24px;
    }

    .dashboard-logo {
        width: 40px;
    }

    .dashboard-items {
        flex-direction: column;
        align-items: center;
    }

    .dashboard-item {
        width: 100%;
        max-width: 300px;
    }

    .logout-button {
        top: 10px;
        right: 10px;
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .dashboard-title {
        font-size: 20px;
    }

    .dashboard-logo {
        width: 35px;
    }

    .dashboard-item {
        width: 100%;
        max-width: 250px;
    }

    .logout-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}