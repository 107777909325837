.Toastify__toast-container {
  z-index: 9999;
  max-width: 300px;
  position: absolute;
  top: 0px;
  width: 100%;

}

.hurricane-toast-progress-bar {
  font-size: 12px;
  line-height: 1.4;
}

.Toastify__toast--warning .Toastify__progress-bar {
  background-color: #FFA500;
}

.custom-toast-icon {
  width: 25px;
  height: 25px;
  margin-right: 55px;
  vertical-align: middle;
}

.hurricane-toast {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.Toastify__toast-body {
  display: flex;
  align-items: center;
}