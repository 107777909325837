.earthquake-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
}

.highest-recently {
  border: 2px solid red;
  border-radius: 10px;
  margin-left: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  min-width: 300px;
  flex-wrap: wrap;
  max-width: 100%;
  box-sizing: border-box;
}

.highest-recently-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.earthquake-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  padding-right: 10px;
  box-sizing: border-box;
}

.earthquake-card {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.earthquake-card:hover {
  background-color: var(--hover-background);
}

.earthquake-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.earthquake-info {
  flex: 1;
}

.highest-earthquake-title,
.earthquake-title {
  font-size: 16px;
  color: white;
  margin: 0;
}

.highest-earthquake-time,
.earthquake-time {
  font-size: 14px;
  color: #ccc;
  margin-top: 5px;
}

.earthquake-more-info-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.earthquake-more-info-button:hover {
  color: var(--earf-blue);
}

@media (max-width: 768px) {
  .highest-recently {
    margin-left: 0;
    min-width: 100%;
  }

  .earthquake-card {
    padding: 8px;
  }

  .earthquake-header h2 {
    font-size: 16px;
  }

  .earthquake-more-info-button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .highest-earthquake-title,
  .earthquake-title {
    font-size: 14px;
  }

  .highest-earthquake-time,
  .earthquake-time {
    font-size: 12px;
  }

  .earthquake-list {
    padding-right: 0;
  }
}