.location-info {
  display: flex;
  flex-wrap: wrap; /* Add this line */
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: #18181b;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  gap: 5px;
}


.location-info-container {
  display: inline-flex;
  align-items: center;
}

.location-time {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0;
  padding: 0;
}

.location-weather {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;

}

.weather-info {
  display: flex;
  align-items: center;
}

.weather-info img {
  width: 30px;
  height: 30px;
}

.weather-details {
  text-align: left;
}

.weather-details p {
  margin: 0;
}

.centeredcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 300px;
  padding-bottom: 20px;
  margin-top: 20px;
}

.descriptionloader {
  width: 35px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #68dfdfbb 90%, #0000) 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

.skeletonloader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.skeletonTime,
.skeletonWeather {
  background: linear-gradient(90deg,#222 25%, #222 50%, #2223 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

.skeletonTime {
  width: 100px;
  height: 20px;
}

.skeletonWeather {
  width: 200px;
  height: 20px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 768px) {

  .location-time,
  .weather-details p {
    font-size: 1em;
  }

  .location-info-container {
    padding: 8px 15px;
  }

  .weather-info img {
    width: 30px;
    height: 30px;
  }
}