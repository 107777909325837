.camera-request-container {
  max-width: 70%;
  padding: 15px;
  margin: 0 auto;
  border-radius: 8px;
  color: white;
  box-sizing: border-box;
  max-height: 100vh;
  overflow-y: auto;
  background-color: var(--content-background);

}

.camera-request-header {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: white;
}

.camera-request-form {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  box-sizing: border-box;
}

.camera-request-form input,
.camera-request-form select,
.camera-request-form textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: white;
  background-color: #1e1e1e;
  box-sizing: border-box;
}

.camera-request-form .form-group {
  flex: 1 1 calc(50% - 20px);
  min-width: 200px;
  box-sizing: border-box;
}

.camera-request-form .full-width {
  flex: 1 1 100%;
}

.camera-request-form input:focus,
.camera-request-form select:focus,
.camera-request-form textarea:focus {
  outline: none;
  box-shadow: 0 0 5px var(--secondary-text-color);
}

.pac-container {
  background-color: #1e1e1e;
  border: none;
  z-index: 10000;
  color: white;
}

.pac-item {
  background-color: #1e1e1e;
  border: none;
  margin: 0;
  color: white;
  box-shadow: none;
}

.pac-item:hover {
  background-color: var(--hover-background);
  cursor: pointer;
  color: white;
}

.pac-item .pac-item-query {
  font-weight: bold;
  color: white;
  border: none;
}

.camera-request-form textarea {
  resize: vertical;
  min-height: 120px;
}

.suggestions-list {
  list-style-type: none;
  background: var(--content-background);
  margin: 0;
  padding: 0;
  max-height: 300px;
  max-width: 50%;
  overflow-y: auto;
  position: absolute;
  width: calc(100% - 24px);
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.camera-request-form button {
  width: 20%;
  padding: 15px;
  background-color: var(--secondary-text-color);
  color: black;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.partner-icon {
  width: 16px;        /* Adjust size as needed */
  height: auto;
  vertical-align: middle;
  margin: 0 4px;
}

.verification-info {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px 15px;
  border-left: 4px solid #00aaff;
  margin-bottom: 20px;
  border-radius: 4px;
  color: #ddd;
  font-size: 1rem;
  font-weight: 700;
}

.verification-info a.verify-email {
  color: #00aaff;
  font-weight: 700;
  text-decoration: none;
}

.verification-info a.verify-email:hover {
  color: #0786c5;
  text-decoration: underline;
}

.camera-request-form button:hover {
  background-color: #59a1c2;
}

.form-button {
  align-items: right;
  justify-content: right;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .camera-request-form .form-group {
    flex: 1 1 100%;
  }

  .camera-request-header {
    font-size: 20px;
  }

  .camera-request-container {
    max-height: 95vh;
    min-width: 100%;
    overflow-y: auto;
  }

  .camera-request-form input,
  .camera-request-form select,
  .camera-request-form textarea {
    padding: 10px;
    font-size: 14px;
  }

  .camera-request-form button {
    width: 100%;

  }

  .camera-request-form button:hover {
    background-color: #59a1c2;
  }

  .form-button {
    align-items: right;
    justify-content: center;
    display: flex;
  }

  .camera-request-form button {
    font-size: 16px;
  }
}