:global {
  .fa-solid {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-background) !important;
  height: 64px;
  z-index: 5501;
  padding: 0 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}


.collapse-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--text-color);
  margin-right: 5px;
  margin-left: -5px;
}

.header-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 3px;
}

.collapse-btn i {
  font-size: 20px;
}

.collapse-btn:hover {
  background-color: var(--hover-background);
}

.title-with-dropdown {
  display: flex;
  align-items: center;
  position: relative;
}

.fancy-title-span {
  font-size: 24px;
  font-weight: bold;
}

.fancy-title-dot {
  font-size: 24px;
  font-weight: bold;
  color: #34ebdf;
}

.vertical-dots-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  position: relative;
}

.vertical-dots-btn:hover {
  color: var(--secondary-text-color);
}

.dropdown-menu {
  position: absolute;
  background-color: var(--primary-background);
  left: 0;
  top: 100%;
  color: white;
  border-radius: 4px;
  padding: 5px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 100;
  width: max-content;
}

.dropdown-header {
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  pointer-events: none;
  cursor: default;
  color: rgb(189, 192, 192);
}

.dropdown-menu li {
  padding: 10px 10px;
  list-style: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600 !important;
  margin: 0;
}

.dropdown-menu li:hover {
  background-color: var(--hover-background);
}

.BETA {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 9px;
  font-weight: 600;
  margin-left: -5px;
  display: inline-block;
  margin-bottom: 10px;
}

.TV {
  font-size: 16px !important;
}

.header-title {
  font-size: 22px;
  font-weight: 700;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.fancy-title-span {
  font-weight: bold;
  color: var(--secondary-text-color);
}

.header-middle {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right {
  position: relative;
  display: flex;
  align-items: center;
}

.dropdown-menu a {
  text-decoration: none !important;
  color: #fff;
}

.dropdown-menu a:visited {
  color: #fff;
}

.dropdown-menu a:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .header {
    padding: 0 8px;
    height: 56px;
  }

  .collapse-btn {
    padding: 8px;
    font-size: 20px;
    margin: 0;
    margin-right: 5px;
  }

  .header-title {
    font-size: 18px;
  }

  .header-middle {
    display: none;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 0 4px;
    height: 58px;
  }

  .collapse-btn {
    padding: 6px;
    font-size: 18px;
  }

  .header-title {
    font-size: 18px;
  }

  .header-middle {
    font-size: 14px;
  }

  .header-right {
    font-size: 14px;
  }
}