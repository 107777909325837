.footer-container {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

.footer-logo {
    margin-bottom: 10px;
}

.footer-logo img {
    width: 50px;
}

.footer-container img {
    width: 50px;
    height: auto;
}

.footer-links {
    justify-content: center;
    gap: 50px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    text-decoration: none !important;

}

.footer-section h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    color: #bbb;
    margin: 0;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    display: inline;
    position: relative;
    margin-right: 15px;
}

.footer-section ul li:not(:last-child)::after {
    content: "|";
    color: #666;
    margin-left: 10px;
}

.footer-section ul li a {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
}

.footer-section ul li a:hover {
    color: var(--earf-blue);
    text-decoration: none !important;

}

.footer-section ul li span {
    font-weight: bold;
    font-size: 14px;
    color: #a7a6a6;
}


.footer-language {
    margin-bottom: 20px;
}

.language-select {
    background-color: #333;
    color: white;
    padding: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.fancy-title-span {
    font-size: 24px;
    font-weight: bold;
    color: var(--earf-blue);
}

.fancy-title-dot {
    font-size: 24px;
    font-weight: bold;
    color: #34ebdf;
}

.footer-social {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.footer-social a {
    color: #ccc;
    font-size: 24px;
    transition: color 0.2s, transform 0.2s;
}

.footer-social a:hover {
    color: var(--earf-blue);
    transform: scale(1.1);
}.footerNotice {
    color: grey;
    font-size: 12px; 
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .footertext {
    color: grey;
    font-size: 12px; 
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  