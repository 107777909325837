.similar-videos-sidebar {
  height: 100%;
  flex: 0 0 20%;
  min-width: 325px;
  max-width: 375px;
  width: 100%;
  background-color: var(--primary-background);
  margin: 0 auto;
  padding-top: 0;
  border-left: 0.5px solid black;
}

.similar-videos-sidebar h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: white;
}


.similar-video-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  padding: 5px;
  border-radius: 5px;
}

.similar-video-item:hover {
  background-color: var(--hover-background);
}

.highlight-blue {
  color: var(--earf-blue);
}

.partner-icon {
  margin-left: 5px;
  width: 16px;
  height: 16px;
}
.similar-video-thumbnail {
  width: 120px;
  height: 80px;
  object-fit: fill;
  border-radius: 4px;
  margin-right: 10px;
  flex-shrink: 0;
}

.similar-video-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

}

.similar-video-content p {
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.channel-thumbnail {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.channel-name {
  font-size: 10px;
  font-weight: bold;
  color: rgb(226, 224, 224);
  text-decoration: none;
}

.sidebar-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 5px;

}

.sidebar-scrollable-content {
  overflow-y: auto;
  flex-grow: 1;
  overflow-y: scroll;
  height: calc(100% - 64px);
  padding: 10px;

}

.sidebar-scrollable-content::-webkit-scrollbar {
  display: none;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.name {
  font-size: 14px;
  color: white;
  margin-top: 2px;
}

.wikidata {
  font-size: 12px;
  color: rgb(221, 221, 221);
  margin-top: 2px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.sidebar-scrollable-content {
  scrollbar-width: none;
}

.similar-video-header-container {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  position: relative;
}

.similar-video-header-container::after {
  content: '';
  display: none;
}

.similar-video-header-container h3,
.similar-video-header-container h4 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  text-align: left;

}

.similar-video-header-container h4 {
  font-size: 14px;
}

.sidebar-tag {
  background-color: #18181b;
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 10px;
}

.skeletonloader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 95%;
  padding: 10px;
  border-radius: 10px;
}

.skeletonHeader,
.skeletonItem {
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}

.skeletonHeader {
  width: 60%;
  height: 20px;
  margin-bottom: 10px;
}

.skeletonItem {
  width: 100%;
  height: 80px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media (max-width: 768px) {
  .similar-videos-sidebar {
    display: none;

  }

  .sidebar-scrollable-content {
    max-height: calc(100vh - 100px);
  }

  .similar-video-item {
    flex-direction: row;
    padding: 8px 5px;
  }

  .similar-video-thumbnail {
    width: 100px;
    height: 56px;
    margin: 0;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .similar-video-content {
    flex-grow: 1;
    padding-left: 10px;
  }

  .similar-video-content p {
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 5px;
    -webkit-line-clamp: 2;
  }

  .sidebar-tags {
    gap: 4px;
    margin-top: 4px;
  }

  .sidebar-tag {
    font-size: 9px;
    padding: 3px 6px;
  }
}