/* Base Styles */
.not-found-container {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
    background-color: var(--content-background);
    /* Matches the dark background */
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 150px;
    color: #3c3c3c;
    /* Slightly dimmed color for the "4" digits */
}

.error-digit {
    font-size: 180px;
    padding: 20px;
    font-weight: bold;
}

.earf-logo {
    width: 120px;
    height: 120px;
    margin: 0 10px;
    /* Add spacing between the "4" digits and logo */
}

.error-message {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    /* Remove any margin */
    color: rgba(255, 255, 255, 0.856);
}

.error-description {
    color: #b9b9b9;
    /* Light gray for the secondary text */
    font-size: 16px;
    margin-top: 5px;
    /* Small spacing between h3 and p */
    margin-bottom: 20px;
    /* Maintain spacing below the description */
    padding: 0;
    /* Ensure no padding is applied */
}

.go-home-button {
    background-color: var(--earf-blue);
    /* Base button color */
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    color: black;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.go-home-button:hover {
    background-color: var(--earf-blue);
    /* Even darker green when active */
}

.go-home-button:focus {
    outline: none;
}

.go-home-button:active {
    background-color: var(--earf-blue);
}

/* Mobile Stylings */
@media (max-width: 768px) {
    .not-found-container {
        padding: 20px;
        /* Reduce padding for smaller screens */
    }

    .error-404 {
        flex-direction: column;
        /* Stack digits and logo vertically */
        font-size: 100px;
        /* Reduce font size */
    }

    .error-digit {
        font-size: 120px;
        /* Reduce font size for digits */
        padding: 10px;
    }

    .earf-logo {
        width: 90px;
        /* Adjust logo size */
        height: 90px;
        margin: 10px 0;
        /* Add spacing for stacked layout */
    }

    .error-message {
        font-size: 20px;
        /* Adjust font size for message */
    }

    .error-description {
        font-size: 14px;
        /* Adjust font size for description */
    }

    .go-home-button {
        font-size: 14px;
        /* Reduce font size for button */
        padding: 8px 12px;
        /* Adjust padding for smaller screens */
    }
}

@media (max-width: 480px) {
    .error-404 {
        font-size: 80px;
        /* Further reduce font size for smaller devices */
    }

    .error-digit {
        font-size: 100px;
        /* Adjust digit font size */
    }

    .earf-logo {
        width: 70px;
        /* Smaller logo size */
        height: 70px;
    }

    .go-home-button {
        font-size: 12px;
        /* Smallest font size for button */
        padding: 6px 10px;
        /* Adjust button padding */
    }
}