/* Main Consent Popup */
.consentPopup {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #111;
    color: white;
    padding: 20px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    animation: slideUp 0.5s ease-out;
    z-index: 9999;
}

/* Confirm Popup */
.confirmPopup {
    position: fixed;
    bottom: 50%;
    left: 50%;
    max-width: 1000px;
    width: 90%;
    transform: translate(-50%, 50%);
    background-color: #111;
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
    z-index: 10000;
}

/* Links */
.consentContent a,
.confirmContent a {
    color: #48a79f;
    text-decoration: none;
    font-weight: bold;
}

.consentContent a:hover,
.confirmContent a:hover {
    color: #51bbb2;
    text-decoration: none;
}

/* Buttons */
.consentButtons,
.confirmButtons {
    margin-top: 10px;
}

.acceptBtn,
.declineBtn,
.leaveBtn,
.stayBtn {
    padding: 10px 20px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    margin: 5px;
    transition: 0.3s;
}

.earfBlue {
    color: var(--earf-blue);
    font-weight: bold;
}

/* Accept & Stay Buttons */
.acceptBtn,
.stayBtn {
    background-color: #2a2a2a;
    color: #e0e0e0;
    border: 1px solid #444;
}

.acceptBtn:hover,
.stayBtn:hover {
    background-color: #3a3a3a;
    color: #ffffff;
}

/* Decline & Leave Buttons */
.declineBtn,
.leaveBtn {
    background-color: #1e1e1e;
    color: #c0c0c0;
    border: 1px solid #333;
}

.declineBtn:hover,
.leaveBtn:hover {
    background-color: #292929;
    color: #ffffff;
}

/* Confirmation Popup */
.confirmPopup {
    position: fixed;
    max-width: 1000px;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    background-color: #111;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.3s ease-in-out;
    z-index: 10000;
    max-width: 900px;
}

/* Buttons */
.acceptBtn,
.declineBtn,
.leaveBtn,
.stayBtn {
    background-color: #2a2a2a;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    margin: 5px;
    transition: 0.3s;
    border-radius: 5px;
}

.acceptBtn:hover,
.stayBtn:hover {
    background: #3a3a3a;
}

.declineBtn:hover,
.leaveBtn:hover {
    background: #292929;
}

/* Slide Up Animation */
@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

/* Fade In Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, 60%);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 50%);
    }
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .confirmPopup {
        width: 90%;
        bottom: 45%;
        left: 50%;
        transform: translate(-50%, 50%);
    }
}