/* VideoSection.module.css */

/* Dark-Themed Skeleton Loader Base */
:global {
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}

.skeleton {
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}
/* Skeleton Loader for Carousel */
.skeleton-thumbnail {
  width: 100%;
  height: 150px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 5px;
}

/* Hide skeleton when image is loaded */
.skeleton-hidden {
  display: none;
}

/* Skeleton Title */
.skeleton-title {
  width: 80%;
  height: 14px;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  margin-top: 5px;
  border-radius: 4px;
}

/* Hide title skeleton when text is loaded */
.skeleton-title-hidden {
  display: none;
}
/* Skeleton Loader for Iframe */
.iframe-placeholder {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

/* Hide Skeleton when iframe loads */
.iframe-hidden {
  display: none;
}










/* Video Section Container */
.video-section-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  color: white;
  flex-wrap: wrap;
}

.video-section-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 40%;
}

/* Carousel Arrow Styles */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: none;
  border: none;
  color: var(--text-color);
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  transition: background 0.3s ease, transform 0.3s ease;
}

.arrow:hover {
  color: var(--earf-blue);
}

.prevArrow {
  left: -40px;
}

.nextArrow {
  right: -40px;
}

/* Title and Channel Info */
.video-section-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.video-section-channel-info {
  display: flex;
  align-items: center;
}

.video-section-channel-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.video-section-channel-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.video-section-wikidata {
  font-size: 14px;
  color: #f3f2f2;
  margin: 0;
  padding: 0;
}

.video-section-channel-name {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: var(--earf-blue);
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  line-height: 1.2;
}

.video-section-channel-name:hover {
  text-decoration: none;
}

.video-section-view-count {
  margin: 0;
  padding: 0;
  color: var(--earf-blue);
}

/* Tags */
.video-section-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.video-section-tag {
  display: inline-block;
  background-color: var(--secondary-background);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.video-section-tag:hover {
  color: var(--earf-blue);
}

/* Description */
.video-section-description {
  font-size: 1rem;
  line-height: 1.5;
  color: #ccc;
  margin-bottom: 20px;
  min-height: 100px;
}

/* Carousel Thumbnail Item */
.video-section-thumbnail-item {
  text-align: left;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.video-section-thumbnail-item:hover {
  transform: translateY(-5px);
}

.video-section-thumbnail-image {
  margin-top: 50px;
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover;
  max-height: 170px;
}

.video-section-carousel {
  max-width: 900px;
  margin-left: 20px;
  height: 300px;
}

.video-section-thumbnail-item.active {
  transform: scale(1.1);
  font-weight: 600;
}

.video-section-thumbnail-title {
  color: var(--earf-blue);
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.video-section-thumbnail-title.active {
  font-weight: 600;
}

.video-section-thumbnail-wikidata {
  font-size: 12px;
  color: #fff;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

/* Right Video Section */
.video-section-right {
  width: 55%;
  margin-left: 20px;
}

.video-section-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-section-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: none;
}

.partner-icon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .video-section-container {
    flex-direction: column;
    align-items: center;
  }

  .video-section-right {
    order: -1;
    width: 100%;
    margin: 0 0 20px 0;
  }

  .video-section-left {
    width: 100%;
    margin-left: 0;
  }

  .video-section-tags {
    margin-top: 10px;
  }

  .video-section-carousel {
    max-width: 80vw;
    margin-left: 20px;
    height: 250px;
  }

  .video-section-thumbnail-item {
    width: 100px;
  }

  .video-section-thumbnail-image {
    width: 100%;
  }
}
