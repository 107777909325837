.knownAsContainer {
  margin-top: 0;
  padding: 0;
  margin-bottom: 10px;
}

.knownAsContainer h3 {
  color: rgb(172, 172, 172);
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 12px;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  margin-top: 5px;
  background-color: #2d2d30;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
}@media (max-width: 768px) {

  .knownAsContainer {
    margin-top: 0;
    padding: 0;
    margin-bottom: -10px;
  }
  .tag {
margin-bottom: 0 !important;
  
  
  }     }  