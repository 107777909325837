.contact-container {
  width: 100vw;
  height: auto; /* Let height adjust to content */
  padding: 10px;
  height: 100vh;
  border-radius: 8px;
  color: white;
  text-align: left; /* Ensure text is left-aligned */
  overflow: hidden;
  list-style-type: none !important; /* Force removal of dots */

}

.contact-header {
  font-size: 25px;
  font-weight: bold;
  color: var(--secondary-text-color);
}

.main-contact-message {
  font-size: 16px;
  text-align: left;
}



.contact-message ul {
  list-style-type: none !important; /* Force removal of dots */
  padding-left: 0 !important;
  margin-left: 0 !important;
}


.main-contact-message ul li {
  margin-bottom: 10px;
}

.main-contact-message ul li a:hover  {
  color: rgb(26, 124, 148);
text-decoration: none;
}
.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.social-icons a {
  color: #ffffff;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.1);
  /* Hover effect */

}

.social-icons svg {
  color: var(--secondary-text-color);
}

.social-icons svg:hover {
  color: rgb(26, 124, 148);
}

/* Style for email links */
.main-contact-message a {
  font-weight: bold;
  /* Make the text bold */
  color: var(--secondary-text-color);
  /* Use your secondary text color */
  text-decoration: none;
  /* Remove underline */
}

.main-contact-messagea:hover {
  text-decoration: none;
  /* Optional: underline the link when hovered */
  color: rgb(26, 124, 148);
}

/* Mobile Styles (for screens up to 480px) */
@media (max-width: 480px) {
  .contact-container {
    width: 95%;
    height:95%;
    padding: 8px;
    height: auto;
    overflow: hidden;
  }
  
  .contact-header {
    font-size: 20px;
  }
  
  .main-contact-message {
    font-size: 14px;
  }
  
  .main-contact-message ul li {
    margin-bottom: 8px;
  }
  
  .social-icons {
    gap: 10px;
    margin-top: 8px;
  }
}