.place-info-bar {
  display: flex;
  align-items: center;
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  font-weight: 600;
  flex-wrap: wrap; /* Add this line */


}

.place-info-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.weather-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  vertical-align: middle;
}

img {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .place-info-bar {
    font-size: 10px;
    max-width: 95%;
  }
}