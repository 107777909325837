.wildfire-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

.wildfire-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(100vh - 270px);
    overflow-y: auto;
}

.wildfire-card,
.most-recent-wildfire-card {
    border-radius: 10px;
    padding: 10px;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: white;
}

.wildfire-card:hover,
.most-recent-wildfire-card:hover {
    background-color: var(--hover-background);
}

.wildfire-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.wildfire-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 10px;
    color: white;
}

.wildfire-title,
.most-recent-wildfire-title {
    font-size: 18px;
    color: white;
    margin: 0;
}

.wildfire-time,
.most-recent-wildfire-time {
    font-size: 14px;
    color: #ccc;
    margin-top: 5px;
}

.wildfire-map-button {
    background-color: var(--primary-background);
    color: var(--text-color);
    border: 1px solid var(--border-color);
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    text-decoration: none;
    white-space: nowrap;
    flex-shrink: 0;
    display: inline-block;
}

.wildfire-map-button:hover {
    color: var(--earf-blue);
    background-color: #222;
}



@media (max-width: 768px) {
    .wildfire-map-button {
        padding: 6px 10px;
        font-size: 12px;
    }

    .wildfire-header {
        font-size: 12px;
    }

    .wildfire-title {
        font-size: 16px;
    }

    .wildfire-time {
        font-size: 12px;
    }

}