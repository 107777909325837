.changelog-container {
  margin: 0 auto;
  padding: 20px;
  position: relative;
  color: white;
  background-color: var(--content-background);
  height: 100%;
  overflow-y: auto;
  max-height: 100vh; 
}

.changelog-back-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.changelog-entry {
  border-radius: 8px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.changelog-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 25px;
}

.changelog-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.changelog-container h1 {
  font-size: 25px;
  color: var(--earf-blue);
}

.changelog-header:hover {
  background-color: var(--hover-background);
}

.changelog-header h3 {
  margin: 0;
  font-size: 16px;
}

.changelog-header p {
  margin: 0;
  font-size: 0.875rem;
}

.expand-btn {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bold;
  width: 100px;
}

.expand-btn:hover {
  color: #fff;
}

.changelog-details {
  padding: 15px;
}

.changelog-details ul {
  margin: 0;
  padding-left: 20px;
}

.changelog-details li {
  margin-bottom: 8px;
  font-size: 0.95rem;
  color: white;
}

.changelog-version-info {
  margin-bottom: 20px;
  padding: 15px;
  background-color: var(--primary-background);
  color: var(--text-color);
  border-left: 4px solid var(--earf-blue);
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}