.gun-violence-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 270px);
  overflow-y: auto;
}

.gun-violence-card {
  border-radius: 10px;
  padding: 10px;
  transition: background-color 0.3s;
}

.gun-violence-card:hover {
  background-color: var(--hover-background);
}


.gun-violence-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.gun-violence-info {
  flex: 1;
}

.coordinates {
  margin-right: 10px;
  font-size: 10px;
  color: white;
  opacity: 0.6;
}

.gun-violence-title {
  font-size: 16px;
  color: white;
  margin: 0;
}


.gun-violence-date {
  font-weight: 600;
  margin: 5px 0;
  font-size: 10px;
  color: white;

}

.gun-violence-details {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  gap: 10px;
}

.gun-violence-details img {
  width: 20px;
  height: 20px;
}

.gun-violence-details .killed {
  color: red;
}

.gun-violence-details .injured {
  color: yellow;
  margin-left: 2px;
}

.icon-label {
  text-align: center;
  font-size: 10px;
  margin-top: 2px;
  display: block;
  color: white;
  font-weight: 600;

}

.gun-violence-more-info-button {
  background-color: var(--primary-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.gun-violence-more-info-button:hover {
  color: var(--earf-blue);
}


@media (max-width: 768px) {
  .gun-violence-card {
    padding: 8px;
  }

  .gun-violence-title {
    font-size: 14px;
  }

  .gun-violence-date {
    font-size: 9px;
  }

  .gun-violence-details {
    font-size: 14px;
    gap: 5px;
  }

  .gun-violence-details img {
    width: 16px;
    height: 16px;
  }

  .icon-label {
    font-size: 9px;
  }

  .gun-violence-more-info-button {
    padding: 6px 10px;
    font-size: 12px;
  }
}