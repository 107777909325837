/* Loader.module.css */
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.loader {
  width: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #68dfdfbb, -20px 0 #68dfdf22;
    background: #68dfdfbb;
  }

  33% {
    box-shadow: 20px 0 #68dfdfbb, -20px 0 #68dfdf22;
    background: #68dfdf22;
  }

  66% {
    box-shadow: 20px 0 #68dfdf22, -20px 0 #68dfdfbb;
    background: #68dfdf22;
  }

  100% {
    box-shadow: 20px 0 #68dfdf22, -20px 0 #68dfdfbb;
    background: #68dfdfbb;
  }
}