:root {
  --primary-background: #0e0e10fb;
  --hover-background: #313538;
  --hover-background-opacity: #313538bd;
  --content-background: #0e0e10;
  --secondary-background: #313538;
  --dashboard-background: rgba(14, 14, 16, 0.6);
  --text-color: #ffffff;
  --secondary-text-color: rgba(104, 223, 223, 0.733);
  --earf-blue: #68dfdfbb;
  --button-color: #252525;
  --button-hover-color: #313538bd;
  --components: #18181b;
  --primary-color: #68dfdfbb;
  /* Updated primary color */
  --primary-color-dark: #609dee;
  /* Updated darker shade of primary color */
  --primary-color-darker: #81a1db;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Dark-Themed Skeleton Loader Base */
:global {
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
}

.skeleton {
  background: linear-gradient(90deg,#222 25%, #222 50%, #222 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
  border-radius: 4px;
}