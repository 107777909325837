.adminPanel {
  position: fixed;
  bottom: 250px;
  left: 10px;
  background-color: var(--primary-background, #222);
  color: var(--text-color, #fff);
  border-radius: 8px;
  padding: 10px;
  width: 200px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 9000;
}

.header {
  text-align: center;
  margin-bottom: 10px;
}

.title {
  margin: 0;
  font-size: 16px;
  color: var(--earf-blue, #0077ff);
}

.button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  background-color: #555;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #666;
}

.logoutButton {
  background-color: red;
}

.logoutButton:hover {
  background-color: darkred;
}